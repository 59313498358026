import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Trans from 'next-translate/Trans';
import Box from '@ui-v2/core/Box/Box';
import Button from '@ui-v2/core/Button/Button';
import Text from '@ui-v2/core/Text/Text';
import { legacySpacings } from '@ui-v2/theme/layout';
import { IconType } from '@ui-v2/types/typography';
import { createTypography } from '@ui-v2/utils/styleUtils';
import { getDuration } from '@utils/dateUtils';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import { getConnectionInformation } from '@utils/itineraryUtils';
import ListItem from '../Lists/ListItem';
import UnorderedList from '../Lists/UnorderedList';
import ItineraryGroundTransitWarning from './ItineraryGroundTransitWarning';
import { DottedLine, LineContainer } from './ItineraryLeg';
import ItinerarySelfTransfer from './ItinerarySelfTransfer';

export interface Props {
  hasNoCheckedBagsConnection?: boolean;
  hasOvernightStay?: boolean;
  nextLegIsTrain?: boolean;
  premiumTierName?: string;
  residency: string;
  showSelfConnectList: boolean;
  showSelfTransfer?: boolean;
  stopDestination?: string;
  stopDuration: number;
  stopOrigin: string;
  toggleInformationModal?: () => void;
}

const ConnectionRow = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: ${spacings['16']}px;
  `,
);

const ConnectionText = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    align-items: flex-start;
    padding: ${spacings['8']}px 0;
    gap: ${spacings['8']}px;
  `,
);

const ConnectionDuration = styled.div(
  ({ theme: { colours, shape, typography } }) => [
    createTypography(typography.heading07),
    css`
      padding: 2px 6px;
      border-radius: ${shape.badge}px;
      background: ${colours.surface.inverse};
      color: ${colours.text.inverse};
    `,
  ],
);

const StyledUnorderList = styled(UnorderedList)(
  ({ theme: { colours } }) => css`
    padding-left: ${legacySpacings.Medium}px;

    li::marker {
      color: ${colours.border.warning};
    }
  `,
);

const StyledListItem = styled(ListItem)(
  ({ theme: { spacings, typography } }) => [
    createTypography(typography.body02),
    css`
      & + & {
        margin-top: ${spacings['8']}px;
      }
    `,
  ],
);

const TextAndIcon = ({
  children,
  icon,
  onClick,
}: {
  children?: React.ReactNode;
  icon: IconType;
  onClick?: () => void;
}) => (
  <Box alignItems="center" display="inline-flex" gap={4} position="relative">
    <Text as="span" fontWeight="bold" variant="body-2">
      {children}
    </Text>
    <Button
      data-cy="cnx-service-search"
      icon={icon}
      onClick={onClick}
      size="iconExtraSmall"
      variant="tertiary"
    />
  </Box>
);

const ItineraryConnectionInformation = ({
  hasNoCheckedBagsConnection,
  hasOvernightStay,
  nextLegIsTrain,
  premiumTierName,
  showSelfConnectList,
  showSelfTransfer,
  stopDestination,
  stopDuration,
  stopOrigin,
  toggleInformationModal,
}: Props) => {
  const { t } = useCmsTranslation();
  const isNextRouteAtDifferentStation = stopOrigin !== stopDestination;
  const connectionInformation = getConnectionInformation({
    hasOvernightStay,
    isNextRouteAtDifferentStation,
    nextLegIsTrain,
    premiumTierName,
    hasNoCheckedBagsConnection,
  });

  const [days, hours, minutes] = getDuration(stopDuration);

  return (
    <ConnectionRow>
      <LineContainer>
        <DottedLine />
      </LineContainer>

      <ConnectionText>
        <ConnectionDuration>
          {`${t('connection', 'Connection')} ${
            days
              ? t(
                  '{{days}}d {{hours}}h {{minutes}}m',
                  `${days}d ${hours}h ${minutes}m`,
                  { days, hours, minutes },
                )
              : t('{{hours}}h {{minutes}}m', `${hours}h ${minutes}m`, {
                  hours,
                  minutes,
                })
          } `}
        </ConnectionDuration>

        {showSelfTransfer && !isNextRouteAtDifferentStation && (
          <ItinerarySelfTransfer />
        )}

        {isNextRouteAtDifferentStation && (
          <ItineraryGroundTransitWarning
            stopDestination={stopDestination}
            stopOrigin={stopOrigin}
          />
        )}

        {showSelfConnectList && (
          <StyledUnorderList>
            {connectionInformation.map((information) => (
              <StyledListItem key={information.i18nKey}>
                <Trans
                  {...information}
                  components={[
                    <TextAndIcon
                      icon="informationIcon"
                      onClick={toggleInformationModal}
                    />,
                  ]}
                />
              </StyledListItem>
            ))}
          </StyledUnorderList>
        )}
      </ConnectionText>
    </ConnectionRow>
  );
};

export default ItineraryConnectionInformation;
